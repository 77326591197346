import {Fragment, useMemo} from "react";
import {ColumnDef} from "@tanstack/react-table";

import DataTable from "../../../../components/dataTable/DataTable";
import {WalletEntriesColumns} from "../../../../components/dataTable/WalletEntriesColumn";

interface Props {
  entries: IOrganisationWalletEntries[]
  size: number
  count: number
  loading: boolean
  pageIndex: number
  setPageIndex: (index: number) => void
}

const WalletEntries = ({entries, size,  count, loading, pageIndex, setPageIndex}: Props) => {
  const columns: ColumnDef<IOrganisationWalletEntries, string>[] = useMemo(() => [...WalletEntriesColumns], []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-body">
            <h5 className="card-title">Wallet Entries</h5>
            <DataTable columns={columns} data={entries} pageIndex={pageIndex} size={size}
                       count={count} loadingState={loading}
                       setPageIndex={setPageIndex}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default WalletEntries;

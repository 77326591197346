import { MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import { NavigateFunction, NavLink, Params, useNavigate, useParams } from "react-router-dom";

import OrgWallet from "./misc/OrgWallet";
import PageLayout from "../../../components/layouts/PageLayout";
import OrgProfileCard from "../../../components/cards/OrgProfileCard";
import WalletTransactionCanvas from "../../../components/offcanvas/WalletTransactionCanvas";
import { MenuType } from "../../../utils/helpers/constants";
import { MockOrganisation, MockOrganisationWallet } from "../../../mocks/MockOrganisation";
import {
  useGetOrganisationMembersQuery, useGetOrganisationQuery,
  useGetOrganisationWalletEntriesQuery, useGetOrganisationWalletQuery
} from "../../../store/slices/dodge.slice";
import TeamMembers from "./misc/TeamMembers";
import OrgTrips from "./misc/OrgTrips";
import OrgPricingConfig from "./misc/OrgPricingConfig";
import { useGetOrganisationPricingConfigQuery, useGetOrganisationTripsQuery } from "../../../store/slices/backoffice.slice";




const OrganisationProfile = () => {
  const { reference }: Readonly<Params> = useParams(), navigate: NavigateFunction = useNavigate();
  const navTabs: string[] = ['Wallet', 'Trips', 'Configurations', 'Team Members',]
  const [size] = useState<number>(20);
  const [walletEntriesPageIndex, setWalletEntriesPageIndex] = useState<number>(1);
  const [tripsPageIndex, setTripsPageIndex] = useState<number>(1);
  // const [pricingConfigPageIndex, setPricingConfigPageIndex] = useState<number>(1);
  const userProfile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile)
  const [activeNav, setActiveNav] = useState<string>("Wallet");
  const [transactionRender, setTransactionRender] = useState<boolean>(false);
  const { data: org, isLoading: loadingOrg } = useGetOrganisationQuery(reference)
  const { data: orgMember, isLoading: loadingMember } = useGetOrganisationMembersQuery(reference)
  const { data: orgWallet, isLoading: loadingWallet } = useGetOrganisationWalletQuery(reference)
  const { data: walletEntries, isLoading: loadingEntries} = useGetOrganisationWalletEntriesQuery(`${reference}?size=${size}&page=${walletEntriesPageIndex}`)
  const { data: orgTrips, isFetching } = useGetOrganisationTripsQuery({reference: reference ? reference : "" , filter:`?size=${size}&page=${tripsPageIndex}`})
  const { data: orgPricingConfigs, isLoading: loadingPricingConfigs } = useGetOrganisationPricingConfigQuery(reference)
  if (!reference) navigate('/saas/organisations')

  const switchActiveNav = (event: MouseEvent, selected: string): void => {
    event.preventDefault()
    setActiveNav(selected)
  }

  
  const transactionCanvas = transactionRender &&
    <WalletTransactionCanvas organisation={reference ?? ""} onchange={() => setTransactionRender(false)} />

  return (
    <PageLayout backEnabled active={MenuType.SAAS} title={`Siju by truQ - ${reference}`}
      loading={loadingOrg || loadingMember || loadingWallet || loadingEntries}>
      {transactionCanvas}
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6" />
          <div className="col-md-6">
            {userProfile.permissions?.edit_admin && userProfile.is_superadmin &&
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <button className="btn btn-primary" onClick={() => setTransactionRender(true)}>
                  <i className="bx bx-credit-card me-1"></i>Wallet Transactions
                </button>
              </div>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <OrgProfileCard profile={org?.data ?? MockOrganisation} />
          </div>
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified">
                  {navTabs.map((item: string, index: number) =>
                    <li key={index} className="nav-item" role="presentation">
                      <NavLink className={`nav-link h-100 centered-content ${activeNav === item ? 'active' : ''}`} to="/"
                        onClick={(event: MouseEvent<HTMLAnchorElement>) => switchActiveNav(event, item)}>
                        <span className="py-auto my-auto">{item}</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="tab-content">
              <div className={`tab-pane ${activeNav === 'Wallet' ? 'active show' : ''}`} role="tabpanel">
                <OrgWallet wallet={orgWallet?.data ?? MockOrganisationWallet} entries={walletEntries?.data ?? []} count={walletEntries?.count ?? 0}
                  loading={loadingEntries} pageIndex={walletEntriesPageIndex} setPageIndex={(index: number) => setWalletEntriesPageIndex(index)} size={size}/>
              </div>
            </div>
            <div className="tab-content">
              <div className={`tab-pane ${activeNav === 'Trips' ? 'active show' : ''}`} role="tabpanel">
                <OrgTrips trips={orgTrips?.data ?? []} loading={isFetching} count={orgTrips?.count ?? 0} pageIndex={tripsPageIndex} setPageIndex={(index: number) => setTripsPageIndex(index)} size={size}/>
              </div>
            </div>
            <div className="tab-content">
              <div className={`tab-pane ${activeNav === 'Configurations' ? 'active show' : ''}`} role="tabpanel">
                <OrgPricingConfig pricingConfigs={orgPricingConfigs?.data ?? []} reference={reference ? reference : ""}/>
              </div>
            </div>
            <div className="tab-content">
              <div className={`tab-pane ${activeNav === 'Team Members' ? 'active show' : ''}`} role="tabpanel">
                <TeamMembers members={orgMember?.data ?? []} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default OrganisationProfile;

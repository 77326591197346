import { CellContext, ColumnDef, ColumnHelper, createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { TripColumns } from '../../../../components/dataTable/TripColumn'
import TableFilter from '../../../../components/dataTable/TableFilter'
import DataTable from '../../../../components/dataTable/DataTable'

interface Props {
    trips: ITrip[]
    size: number
    count: number
    loading: boolean
    pageIndex: number
    setPageIndex: (index: number) => void
  }

const OrgTrips = ({size, trips, count, loading, pageIndex, setPageIndex}: Props) => {

    const navigate: NavigateFunction = useNavigate()
    const column: ColumnHelper<ITrip> = createColumnHelper<ITrip>()
    const actionColumn: ColumnDef<ITrip, string> = column.accessor('id', {
        header: () => <span>Action</span>,
        enableSorting: false,
        cell: (info: CellContext<ITrip, string>) => {
            let reference: string = info.row.original.reference
            return (
                <span className="text-center pointer">
                    <button className="btn btn-sm btn-primary"
                        onClick={() => navigate(`/trips/${reference}`, { state: reference })}>
                        Trip Details
                    </button>
                </span>
            )
        }
    })

    const columns: ColumnDef<ITrip, string>[] = useMemo(() => [...TripColumns, actionColumn], []);


    const searchTrip = (searchParams: string) => {
        console.log(searchParams)
    }


    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header custom-card-header">
                        <h1 className="card-title">Organisation Trips</h1>
                        <TableFilter searchFn={searchTrip} />
                    </div>
                    <div className="card-body">
                        <DataTable columns={columns} data={trips} size={size} pageIndex={pageIndex} loadingState={loading}
                            count={count} setPageIndex={setPageIndex} 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgTrips
import { Fragment } from 'react'

interface Props {
    members: IOrganisationMember[]
  }
const TeamMembers = ({members}: Props) => {
    return (
        <Fragment>
            <h5 className="card-title mt-4">Team Members</h5>
            <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0">
                    <thead className="bg-light">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {members.map((member: IOrganisationMember, index: number) => (
                            <tr key={index}>
                                <td><h5 className="font-size-14 m-0">{member.first_name} {member.last_name}</h5></td>
                                <td> {member.email}</td>
                                <td> {member.phone}</td>
                                <td>
                                    <div className="badge bg-primary-subtle text-primary font-size-11">{member.role.name}</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default TeamMembers
import { useState, MouseEvent } from 'react'
import { NavLink } from 'react-router-dom';
import PricingConfigBreakdown from './PricingConfigBreakdown';
import { IOrganisationPricingConfig } from '../../../../interfaces/configs';

interface Props {
    pricingConfigs: IOrganisationPricingConfig[];
    reference: string
}
const OrgPricingConfig = ({ pricingConfigs, reference }: Props) => {
    const navTabs: string[] = ['Your Configurations', 'Other Configurations']
    const [activeNav, setActiveNav] = useState<string>("Your Configurations");

    const switchActiveNav = (event: MouseEvent, selected: string): void => {
        event.preventDefault()
        setActiveNav(selected)
    }

    const ownPricingConfigs = pricingConfigs?.filter((config: IOrganisationPricingConfig) => config.organisation.reference === reference) ?? []

    const otherPricingConfigs = pricingConfigs?.filter((config: IOrganisationPricingConfig) => config.sub_organisation.reference === reference) ?? []

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header custom-card-header">
                        <h1 className="card-title">Organisation Pricing Configurations</h1>
                    </div>
                    <div className="card-body">
                        <ul className="nav nav-pills nav-justified">
                            {navTabs.map((item: string, index: number) =>
                                <li key={index} className="nav-item" role="presentation">
                                    <NavLink className={`nav-link h-100 centered-content ${activeNav === item ? 'active' : ''}`} to="/"
                                        onClick={(event: MouseEvent) => switchActiveNav(event, item)}>
                                        <span className="py-auto my-auto">{item}</span>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                        <div className="tab-content">
                            <div className={`tab-pane ${activeNav === 'Your Configurations' ? 'active show' : ''}`} role="tabpanel">
                                <PricingConfigBreakdown type={activeNav} pricingConfig={ownPricingConfigs} />
                            </div>
                            <div className="tab-content">
                                <div className={`tab-pane ${activeNav === 'Other Configurations' ? 'active show' : ''}`} role="tabpanel">
                                    <PricingConfigBreakdown type={activeNav} pricingConfig={otherPricingConfigs} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgPricingConfig
import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import moment from "moment";
import {orgFlowMap} from "../../utils/helpers/mappers";

const column: ColumnHelper<IOrganisation> = createColumnHelper<IOrganisation>()

export const OrganisationColumns: ColumnDef<IOrganisation, string>[] = [
  column.accessor('name', {
    header: () => <span>Name</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('reference', {
    header: () => <span>Reference</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('org_flow', {
    header: () => <span>Flow</span>,
    cell: info => <span>{info.getValue() && orgFlowMap[info.getValue()] ? orgFlowMap[info.getValue()]['label'] : ""}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('support_email', {
    header: () => <span>Support Email</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('support_phone', {
    header: () => <span>Support Phone</span>,
    cell: info => info.getValue(),
    footer: info => info.column.id,
  }),
  column.accessor('created_at', {
    header: () => <span>Date Joined</span>,
    sortingFn: "datetime",
    cell: info => <span>{moment(info.getValue()).format('LL')}</span>,
    footer: info => info.column.id,
  })
];

import {BaseQueryFn, createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import TokenHelper from "../../utils/helpers/tokenHelper"
import {getBaseUrl} from "../../utils/services/axios.service"

const backOfficeBaseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: getBaseUrl()['backoffice'],
  prepareHeaders: (headers: Headers) => {
    const token = TokenHelper.getToken()
    headers.set("Content-Type", 'application/json')
    if (token) headers.set("Authorization", `Bearer ${token}`)
    return headers
  }
})

export const backOfficeQuery = createApi({
  baseQuery: backOfficeBaseQuery,
  reducerPath: 'backOfficeQuery',
  tagTypes: [
    'Staff', 'Voucher', 'Signatory', 'Trips', 'Configs', 'Driver', 'DriverLeads', 'LoanConfig', 'LoanRequest',
    'PerformanceEntries', 'Documents', 'Messaging', 'Docs', 'WebsiteLeads'],
  endpoints: builder => ({
    getStaff: builder.query({
      query: filter => `/user-service/staff/${filter}`,
      providesTags: ['Staff']
    }),
    addStaff: builder.mutation({
      query: (body) => ({url: `/user-service/add-staff`, method: 'POST', body}),
      invalidatesTags: ['Staff']
    }),
    banStaff: builder.mutation({
      query: ({filter, body}) => ({url: `/user-service/ban-staff/${filter}`, method: 'PATCH', body}),
      invalidatesTags: ['Staff']
    }),
    getVouchers: builder.query({
      query: filter => `/voucher-service/vouchers/${filter}`,
      providesTags: ['Voucher']
    }),
    createVoucher: builder.mutation({
      query: (body) => ({url: `/voucher-service/vouchers/create`, method: 'POST', body}),
      invalidatesTags: ['Voucher']
    }),
    sendVoucher: builder.mutation({
      query: ({filter, body}) => ({url: `/voucher-service/vouchers/send/${filter}`, method: 'POST', body}),
      invalidatesTags: ['Voucher']
    }),
    updateReceipt: builder.mutation({
      query: ({filter, body}) => ({url: `/voucher-service/vouchers/attach-receipt/${filter}`, method: 'POST', body}),
      invalidatesTags: ['Voucher']
    }),
    getSignatories: builder.query({
      query: filter => `/voucher-service/signatory/${filter}`,
      providesTags: ['Signatory']
    }),
    createSignatory: builder.mutation({
      query: (body) => ({url: `/voucher-service/signatory/create`, method: 'POST', body}),
      invalidatesTags: ['Signatory']
    }),
    updateSignatory: builder.mutation({
      query: ({filter, body}) => ({url: `/voucher-service/signatory/update/${filter}`, method: 'PATCH', body}),
      invalidatesTags: ['Signatory']
    }),
    getStandardTrips: builder.query({
      query: filter => `/integration-service/trips/standard${filter}`,
      providesTags: ['Trips']
    }),
    // getOrganisationTrips: builder.query({
    //   query: filter => `/integration-service/trips/${filter}`,
    //   providesTags: ['Trips']
    // }),
    getOrganisationTrips: builder.query({
      query: ({ reference, filter }: { reference: string; filter: string }) => ({url:`/integration-service/trips/${filter}`, headers: {organisation: reference}}),
      providesTags: ['Configs']
    }),
    getOrganisationPricingConfig: builder.query({
      query: filter => ({url:`/integration-service/trips/pricing/`, headers:{organisation: filter}
      }),
      providesTags: ['Trips']
    }),
    getTrip: builder.query({
      query: filter => `/integration-service/trips/details/${filter}`,
      providesTags: ['Trips']
    }),
    updateTrip: builder.mutation({
      query: ({filter, body}) => ({url: `/integration-service/${filter}`, method: 'POST', body}),
      invalidatesTags: ['Trips']
    }),
    getDriver: builder.query({
      query: filter => `/integration-service/partners/drivers/${filter}`,
      providesTags: ['Driver']
    }),
    getDriverLeads: builder.query({
      query: filter => `/leads-service/drivers/${filter}`,
      providesTags: ['DriverLeads']
    }),
    onboardDriverLead: builder.mutation({
      query: () => `/leads-service/drivers/onboard`,
      invalidatesTags: ['DriverLeads']
    }),
    updateDriverLead: builder.mutation({
      query: ({filter, body}) => ({url: `/leads-service/drivers/${filter}`, method: 'PATCH', body}),
      invalidatesTags: ['DriverLeads']
    }),
    getWebsiteLeads: builder.query({
      query: () => `/integration-service/onboarding`,
      providesTags: ['WebsiteLeads']
    }),
    getPerformanceEntries: builder.query({
      query: filter => `/partners-service/performance/entries/${filter}`,
      providesTags: ['PerformanceEntries']
    }),
    awardPerformancePoints: builder.mutation({
      query: (body) => ({url: `/partners-service/performance/points`, method: 'POST', body}),
      invalidatesTags: ['PerformanceEntries']
    }),
    getExpiringDocuments: builder.query({
      query: filter => `/partners-service/documents/expiry-documents/${filter}`,
      providesTags: ['Documents']
    }),
    getMessaging: builder.query({
      query: filter => `/messaging-service/messaging/messages/${filter}`,
      providesTags: ['Messaging']
    }),
    sendMessage: builder.mutation({
      query: ({filter, body}) => ({url: `/messaging-service/messaging/${filter}`, method: 'POST', body}),
      invalidatesTags: ['Messaging']
    }),
    getTopics: builder.query({
      query: filter => `/docs-service/directory/topics/${filter}`,
      providesTags: ['Docs']
    }),
    getContent: builder.query({
      query: filter => `/docs-service/directory/content/${filter}`,
      providesTags: ['Docs']
    }),
    createTopic: builder.mutation({
      query: (body) => ({url: `/docs-service/directory/topics`, method: 'POST', body}),
      invalidatesTags: ['Docs']
    }),
    createContent: builder.mutation({
      query: (body) => ({url: `/docs-service/directory/content`, method: 'POST', body}),
      invalidatesTags: ['Docs']
    }),
    updateTopic: builder.mutation({
      query: ({filter, body}) => ({url: `/docs-service/directory/topics/${filter}`, method: 'PATCH', body}),
      invalidatesTags: ['Docs']
    }),
    updateContent: builder.mutation({
      query: ({filter, body}) => ({url: `/docs-service/directory/content/${filter}`, method: 'PATCH', body}),
      invalidatesTags: ['Docs']
    }),
    updateContentStatus: builder.mutation({
      query: ({filter, body}) => ({
        url: `/docs-service/directory/content/update-status/${filter}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Docs']
    }),
    getLoanConfiguration: builder.query({
      query: () => `/integration-service/partners/configure-quick-pay`,
      providesTags: ['LoanConfig']
    }),
    addLoanConfiguration: builder.mutation({
      query: body => ({
        url: `/integration-service/partners/configure-quick-pay`, method: 'POST', body,
      }),
      invalidatesTags: ['LoanConfig']
    }),
    updateLoanConfiguration: builder.mutation({
      query: ({id, body}) => ({
        url: `/integration-service/partners/configure-quick-pay/${id}`, method: 'PATCH', body,
      }),
      invalidatesTags: ['LoanConfig']
    }),
    deleteLoanConfiguration: builder.mutation({
      query: id => ({url: `/integration-service/partners/configure-quick-pay/${id}`, method: 'DELETE',}),
      invalidatesTags: ['LoanConfig']
    }),
    getLoanRequest: builder.query({
      query: filter => `/integration-service/partners/quick-pay-requests/${filter}`,
      providesTags: ['LoanRequest']
    }),
    rejectLoanRequest: builder.mutation({
      query: id => ({url: `/integration-service/partners/quick-pay-requests/${id}/deny`, method: 'GET'}),
      invalidatesTags: ['LoanRequest']
    }),
    approveLoanRequest: builder.mutation({
      query: id => ({url: `/integration-service/partners/quick-pay-requests/${id}/approve`, method: 'GET'}),
      invalidatesTags: ['LoanRequest']
    }),
    repayLoanRequest: builder.mutation({
      query: id => ({url: `/integration-service/partners/quick-pay-requests/${id}/repay`, method: 'GET'}),
      invalidatesTags: ['LoanRequest']
    }),
    deleteLoanRequest: builder.mutation({
      query: id => ({url: `/integration-service/partners/quick-pay-requests/${id}`, method: 'DELETE',}),
      invalidatesTags: ['LoanRequest']
    }),
    getOrganisationDriverProfile: builder.query({
      query: () => `/integration-service/partners/organisation-profile`
    }),
  })
})

export const {
  useGetStaffQuery,
  useAddStaffMutation,
  useBanStaffMutation,
  useGetVouchersQuery,
  useCreateVoucherMutation,
  useSendVoucherMutation,
  useUpdateReceiptMutation,
  useGetSignatoriesQuery,
  useCreateSignatoryMutation,
  useUpdateSignatoryMutation,
  useGetStandardTripsQuery,
  useGetOrganisationTripsQuery,
  useGetOrganisationPricingConfigQuery,
  useGetTripQuery,
  useUpdateTripMutation,
  useGetDriverQuery,
  useGetPerformanceEntriesQuery,
  useAwardPerformancePointsMutation,
  useGetDriverLeadsQuery,
  useGetWebsiteLeadsQuery,
  useOnboardDriverLeadMutation,
  useUpdateDriverLeadMutation,
  useGetExpiringDocumentsQuery,
  useGetMessagingQuery,
  useSendMessageMutation,
  useGetTopicsQuery,
  useGetContentQuery,
  useCreateTopicMutation,
  useCreateContentMutation,
  useUpdateTopicMutation,
  useUpdateContentMutation,
  useUpdateContentStatusMutation,
  useDeleteLoanConfigurationMutation,
  useGetLoanConfigurationQuery,
  useAddLoanConfigurationMutation,
  useUpdateLoanConfigurationMutation,
  useGetLoanRequestQuery,
  useRejectLoanRequestMutation,
  useApproveLoanRequestMutation,
  useRepayLoanRequestMutation,
  useDeleteLoanRequestMutation,
  useGetOrganisationDriverProfileQuery
} = backOfficeQuery;

import { useState } from "react";
import { NavLink } from "react-router-dom";
import { IOrganisationPricingConfig } from "../../../../interfaces/configs";

interface Props {
    type: string;
    pricingConfig: IOrganisationPricingConfig[];
}

const PricingConfigBreakdown = ({ type, pricingConfig }: Props) => {
    const navTabs = type === "Your Configurations" ? pricingConfig.map(config => ({
        name: config?.sub_organisation.name,
        id: config?.id,
        category_size: config?.category_size
    })) : pricingConfig.map(config => ({
        name: config?.organisation.name,
        id: config?.id,
        category_size: config?.category_size
    }))
    const [activeNav, setActiveNav] = useState<string>(navTabs[0]?.id ?? '');

    const switchActiveNav = (event: React.MouseEvent<HTMLAnchorElement>, selectedId: string): void => {
        event.preventDefault()
        setActiveNav(selectedId)
    }

    const activeConfig = pricingConfig.find(config => config.id === activeNav);


    const processConfigData = (data: IOrganisationPricingConfig[]) => {
        if (!data || !data.length) return [];

        return data.flatMap((config) => {
            const result = [];

            if (Array.isArray(config.fixed_pricing_config) && config.fixed_pricing_config.length > 0) {
                result.push(...config.fixed_pricing_config.map((item, index) => ({
                    name: `Config ${index + 1}`,
                    value: `Price: ${item.price} NGN, Distance: ${item.distance} Km`,
                })));
            }

            if (Array.isArray(config.price_list) && config.price_list.length > 0) {
                result.push(...config.price_list.flatMap((config, index) =>
                    Object.entries(config).map(([key, values]) => ({
                        name: key,
                        value: Array.isArray(values) ? values.map((v) => `${Object.keys(v)[0]}: ${Object.values(v)[0]}`).join(",  ") : '',
                    }))
                ));
            }

            if (typeof config.stop_config === "object" && Object.keys(config.stop_config).length > 0) {
                result.push(...Object.entries(config.stop_config).map(([key, value]) => ({
                    name: key,
                    value: value,
                })));
            }

            if (Array.isArray(config.variable_pricing_config) && config.variable_pricing_config.length > 0) {
                result.push(...config.variable_pricing_config.flatMap((config, index) =>
                    Object.entries(config).map(([key, value]) => ({
                        name: key,
                        value: value,
                    }))
                ));
            }

            return result;
        });
    };

    const configDetails = activeConfig ? processConfigData([activeConfig]) : [];

    return (
        pricingConfig.length === 0 ? (<div className="text-center my-5">No Configurations have been set</div>) :
            (<div className='row'>
                <div className='col-md-12'>
                    <h6 className="py-4">
                        {`These are price configurations ${type === "Your Configurations"
                            ? "you set on behalf of other organisation"
                            : "other organisation set on your behalf"
                            }`}
                    </h6>

                    <div className="d-flex justify-content-between" style={{ height: "300px" }}>
                        <ul className="nav nav-pills custom-scroll col-3 h-100">
                            {navTabs.map((item, index) =>
                                <li key={index} className="nav-item w-100" role="presentation">
                                    <NavLink className={`nav-link  text-start border border-secondary w-100 my-1 ${activeNav === item.id ? 'active' : ''}`} to="/"
                                        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => switchActiveNav(event, item.id)}>
                                        <span className="py-auto my-auto">{item.name} {item.category_size}-Tonne</span>
                                    </NavLink>
                                </li>
                            )}
                        </ul>

                        <div className="col-8 h-100 custom-scroll">
                            <h6>{activeConfig && `${activeConfig?.trip_type} ${activeConfig?.category_size} TONNE`}</h6>
                            {configDetails.map((detail, index) => (
                                <div className="input-group py-1" key={index}>
                                    <div className="input-group-text">{detail.name}</div>
                                    <input type="text" className="form-control" id="configDetailInput" value={String(detail.value)} disabled />
                                </div>))}
                        </div>
                    </div>
                </div>
            </div>)
    )
}

export default PricingConfigBreakdown
import { Fragment } from "react";
import NumberCard from "../../../../components/cards/NumberCard";
import { formatAmountToString } from "../../../../utils/helpers/logicHelper";
import WalletEntries from "./WalletEntries";

interface Props {
  wallet: IOrganisationWallet
  entries: IOrganisationWalletEntries[]
  size: number
  count: number
  loading: boolean
  pageIndex: number
  setPageIndex: (index: number) => void
}

const OrgWallet = ({ wallet, entries, size, count, loading, pageIndex, setPageIndex }: Props) => {
  

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-4">
          <NumberCard text="Ledger Balance" value={`${wallet.currency}${formatAmountToString(wallet.ledger_balance)}`} />
        </div>
        <div className="col-md-4">
          <NumberCard text="Available Balance"
            value={`${wallet.currency}${formatAmountToString(wallet.available_balance)}`} />
        </div>
        <div className="col-md-4">
          <NumberCard text="Credit Balance" value={`${wallet.currency}${formatAmountToString(wallet.credit_balance)}`} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-body">
            <h5 className="font-size-16 mb-4">Wallet Information</h5>
            <ul className="list-unstyled mb-0 pt-1">
              <li className="py-1">
                Withdraw Status: {wallet.withdrawal_configs.can_withdraw ?
                  <i className="bx bx-check-circle text-success" /> : <i className="bx bx-block text-danger" />}
              </li>
              <li className="py-1">
                Advanced Payment Status: {wallet.advanced_payment_configs.advanced_payment_status ?
                  <i className="bx bx-check-circle text-success" /> : <i className="bx bx-block text-danger" />}
              </li>
              <li className="py-1">
                Advanced Payment Interest: {wallet.advanced_payment_configs.interest_rate ?? 0}%
              </li>
              <li className="py-1">
                Advanced Payment Outstanding Cap:&nbsp;
                {wallet.currency}{formatAmountToString(wallet.advanced_payment_configs.outstanding_cap) ?? 0}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <WalletEntries entries={entries ?? []} count={count ?? 0}
        loading={loading} pageIndex={pageIndex} setPageIndex={setPageIndex} size={size}/>
    </Fragment>
  )
}

export default OrgWallet;
